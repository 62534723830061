export const ratingTitleList = [
    {
        tabId: "1",
        src: "/Cheapestessay.webp",
        title: "Cheapestessay",
        name: "CheapestEssay reviews",
        rating: 4.7
    },
    {
        tabId: "2",
        src: "/reviews-io.png",
        title: "Reviews.io",
        name: "Reviews.io reviews",
        rating: 4.5
    },
    {
        tabId: "3",
        src: "/sitejabber.webp",
        title: "Sitejabbar",
        name: "sitejabber reviews",
        rating: 4.4
    }
]