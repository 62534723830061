import React, { useRef, useState } from "react";
import Link from 'next/link';
// react slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Rating from 'react-rating';
// scss
import styles from "./feedback.module.scss";

import dynamic from 'next/dynamic';
const Img = dynamic(() => import('../../Common/image'));
import { ratingTitleList } from "./data";
import Image from "next/image";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import {convertDate} from "../../../helper/utilHelper";

export default function Feedback(props) {

  const [activeTab, setActiveTab] = useState("1");
  let showReviewType = useRef("googleReviews");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      showReviewType.current = (tab === '1' ? 'googleReviews' : (tab === '2' ? 'trustPilot' : 'siteJabber'));
    }
  };

  let reviewArr = [];
  if (props.reviewData) {
    const data = props.reviewData;
    reviewArr = data.review_list;

  }
  const reviewTitle = props.reviewData && props.reviewData.title ? props.reviewData.title : "Client's Feedback for Essay Help that Keeps Us Motivated";

  const reviewersCount = props.ratingData && props.ratingData.no_of_reviews ? props.ratingData.no_of_reviews : 0;
  const ratingValue = props.ratingData && props.ratingData.over_all_rating ? parseFloat(props.ratingData.over_all_rating) : 0.00;
  const ratingValueStars = (ratingValue && !!ratingValue) ? ratingValue : 4.5;

  const { linkLocale } = props;

  return <>
    <section className={`${styles.feedback} ${props.className ? props.className : ''}`} id="reviews">
      <Container>
        <div className="text-center">
          <h2 className="section-title">
            {reviewTitle}
          </h2>
        </div>
        <Tab.Container defaultActiveKey={activeTab}>
          <Nav variant="tabs" className={`${styles.nav} justify-content-center nav-pills`}>
            {
              ratingTitleList.map(({ tabId, src, title, name, rating }) => (
                <Nav.Item key={tabId} className={styles.navItem}>
                  <Nav.Link
                    onClick={() => toggle(tabId)}
                    className={`${styles.navLink} ${activeTab === tabId ? styles.active : ''}`}
                    eventKey={tabId}
                  >
                    <div className={`d-lg-flex align-items-center`}>
                      <Image src={src} title={title} alt={title} width={40} height={40} />
                      <div className={`${styles.rateDetail} d-lg-flex`}>
                        <div className={styles.left}>
                          <div className={styles.name}>{name}</div>
                          <div className={`${styles.star} mb-0`}>
                            <Image src="/stars.svg" title="Stars" alt="stars" width={100} height={14} />
                          </div>
                        </div>
                        <div className={`${styles.rating} mb-0`}>
                          <span>{rating}</span>
                        </div>
                      </div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
          <Tab.Content className={styles.tabContent}>
            <div className={styles.feedbackSlider}>
              <Slider
                dots={true}
                arrows={false}
                autplay={true}
                speed={500}
                slidesToShow={reviewArr && reviewArr[showReviewType.current] ? (reviewArr[showReviewType.current].length > 0
                && reviewArr[showReviewType.current].length < 3 ? reviewArr[showReviewType.current].length : 3) : 1}
                slidesToScroll={1}
                responsive={[
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: reviewArr && reviewArr[showReviewType.current] ? (reviewArr[showReviewType.current].length > 0 && reviewArr[showReviewType.current].length < 2 ? reviewArr[showReviewType.current].length : 2) : 1
                    }
                  },
                  {
                    breakpoint: 575,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]}
              >
                {reviewArr && reviewArr[showReviewType.current] && reviewArr[showReviewType.current].length > 0 ?
                  reviewArr[showReviewType.current].map(data => {
                    return <div className={styles.feedbackDetail} key={data.customer_name}>
                      <div className={styles.customerDetail}>
                        <div className={styles.name}>{data.customer_name}</div>
                        <span className={styles.id}>{convertDate(data.review_date)}</span>
                      </div>
                      <div className={styles.info}>
                        <div className={styles.rating}>
                          <Rating
                            readonly={true}
                            initialRating={data.rating}
                            fractions={2}
                            emptySymbol={<Img src="/empty.svg" title="Empty" className="icon" alt="ratingImg" width={18} height={18} />}
                            fullSymbol={<Img src="/full.svg" title="Full" className="icon" alt="ratingImg" width={18} height={18} />}
                          />
                        </div>
                        <div className={styles.reviewTitle}>
                          <strong style={{ 'fontWeight': '500' }}>{data.review_headline}</strong></div>
                        <p className={styles.content}>
                          {data.review}
                        </p>
                      </div>
                    </div>
                  })
                  : <div className="noReview-found">No Feedback found</div>
                }
              </Slider>
            </div>
          </Tab.Content>
        </Tab.Container>
        {
          (reviewersCount && ratingValue) ?
            <>
              <p className={`${styles.overRating} text-center`}>
                <span className="me-3">Overall Rating</span>
                <Rating
                  readonly={true}
                  initialRating={ratingValueStars}
                  fractions={2}
                  emptySymbol={<Img src="/empty.svg" title="Empty" className="icon" alt="ratingImg" width={18} height={18} />}
                  fullSymbol={<Img src="/full.svg" title="Full" className="icon" alt="ratingImg" width={18} height={18} />}
                />
                <span className="ms-2"><b>{ratingValue}</b> out of 5 based on <b>{reviewersCount}</b> Reviews.</span>
              </p>
              <div className={`text-center`}>
                <Link
                  href={"/reviews"}
                  locale={linkLocale}
                  prefetch={false}
                  className={`btn outlineSecondaryBtn ${styles.outlineSecondaryBtn}`}>
                  Check All Reviews</Link>
              </div>
            </>
            : null}
      </Container>
    </section>
  </>;
}